import React from 'react';
import './DevelopmentForCats.css';

const DevelopmentForCats = () => {
    return (
        <div className="cat-dev-container">
            <div className="cat-content">
                <div className="cat-text">
                    <h1 className="cat-header">Welcome to Development for Cats!</h1>
                    <p className="intro-text">
                        Have you ever wondered what it's like to develop software as a cat?
                        Well, wonder no more! We're here to show you the purr-fect workflow for
                        feline developers.
                    </p>
                    <ul className="steps-list">
                        <li>1. Nap on the keyboard for at least 8 hours a day.</li>
                        <li>2. Bat at the cursor like it's a toy.</li>
                        <li>3. Insist on sitting on the monitor during important meetings.</li>
                        <li>4. Ignore all bug reports because they are clearly not a priority.</li>
                        <li>5. Meow for snacks every 30 minutes.</li>
                    </ul>
                    <footer className="footer">
                        <p>Remember, in the world of cat development, every problem is a nap opportunity!</p>
                        <a href="https://www.catster.com" target="_blank" rel="noreferrer noopener" className="footer-link">
                            More About Cats
                        </a>
                    </footer>
                </div>
                <img
                    src="https://placecats.com/400/400"
                    alt="Cute Cat"
                    className="cat-image"
                />
            </div>
        </div>
    );
};

export default DevelopmentForCats;
