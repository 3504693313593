import React, { useEffect, useState } from 'react';
import { Typewriter } from 'react-simple-typewriter';

const Home = () => {
    const [animationComplete, setAnimationComplete] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setAnimationComplete(true);
        }, 2500);
        return () => clearTimeout(timer);
    }, []);

    return (
        <section id="home">
            <div className="home-container">
                <div className={`header ${animationComplete ? 'move-up' : ''}`}>
                    {!animationComplete ? (
                        <h1 className="typewriter-text">
                            <Typewriter
                                words={['Mahdi Osman']}
                                loop={1}
                                cursor
                                cursorStyle='_'
                                typeSpeed={100}
                                deleteSpeed={50}
                                delaySpeed={800}
                                onLoopDone={() => setAnimationComplete(true)}
                            />
                        </h1>
                    ) : (
                        <h2>Mahdi Osman</h2>
                    )}
                </div>
                {animationComplete && (
                    <div className="content fade-in">
                        <p>
                            I am a recent graduate from the <a href='https://unic.ac.cy/'>University of Nicosia</a> with a degree in Computer Science. I enjoy working with C++, C#, and Java, and have experience with React.js. My passion lies in backend development using Node.js for building bots and APIs. I am also fascinated by AI and its workings.
                        </p>
                        <div className="technologies">

                            <a href='https://github.com/MahdiOsman' target='_blank' rel='noreferrer'><i className="devicon-github-original colored"></i></a>
                            <i className="devicon-java-plain colored"></i>
                            <i className="devicon-javascript-plain colored"></i>
                            <i className="devicon-react-original colored"></i>
                            <i className="devicon-nodejs-plain colored"></i>
                            <i className="devicon-csharp-plain colored"></i>
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
};

export default Home;
