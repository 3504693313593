import React from 'react';
import { FaFileDownload } from 'react-icons/fa'; // Install react-icons if needed

const Resume = () => {

  const handleDownload = () => {
    const downloadLink = document.createElement('a');
    downloadLink.href = `${process.env.PUBLIC_URL}/MahdiOsmanResume.pdf`;
    downloadLink.download = 'MahdiOsmanResume.pdf';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <section id="resume">
      <div className="content">
        <h3 className="tagline">"Empowering ideas through design and code"</h3>
        <p>Download my resume to learn more about my skills and experience.</p>
        <div className="button-container">
          <button id="downloadButton" onClick={handleDownload}>
            <FaFileDownload className="icon" /> Download Resume
          </button>
        </div>
      </div>
    </section>

  );
};

export default Resume;
