import React, { useEffect, useMemo, useState } from 'react';
import Home from './components/Home';
import Projects from './components/Projects';
import Resume from './components/Resume';
import './App.css';

function App() {
  const sections = useMemo(() => ['home', 'projects', 'resume'], []);
  const [currentSection, setCurrentSection] = useState(0);

  useEffect(() => {
    const handleScroll = (e) => {
      e.preventDefault();
      const delta = e.wheelDelta ? e.wheelDelta : -e.detail;
      if (delta < 0) {
        setCurrentSection((prev) => Math.min(prev + 1, sections.length - 1));
      } else {
        setCurrentSection((prev) => Math.max(prev - 1, 0));
      }
    };

    let touchStartY = 0;

    const handleTouchStart = (e) => {
      touchStartY = e.touches[0].clientY;
    };

    const handleTouchEnd = (e) => {
      const touchEndY = e.changedTouches[0].clientY;
      const deltaY = touchStartY - touchEndY;

      if (deltaY > 50) {
        setCurrentSection((prev) => Math.min(prev + 1, sections.length - 1));
      } else if (deltaY < -50) {
        setCurrentSection((prev) => Math.max(prev - 1, 0));
      }
    };

    window.addEventListener('wheel', handleScroll);
    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchend', handleTouchEnd);

    return () => {
      window.removeEventListener('wheel', handleScroll);
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, [sections.length]);

  useEffect(() => {
    const sectionId = sections[currentSection];
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [currentSection, sections]);

  return (
    <div className="App">
      <div className="sidebar">
        {sections.map((section, index) => (
          <div
            key={section}
            className={`dot ${currentSection === index ? 'active' : ''}`}
            onClick={() => setCurrentSection(index)}
          />
        ))}
      </div>
      <section id="home"><Home /></section>
      <section id="projects"><Projects /></section>
      <section id="resume"><Resume /></section>
    </div>
  );
}

export default App;
