import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import DevelopmentForCats from './DevelopmentForCats';

const Projects = () => {
  const [repos, setRepos] = useState([]);
  const [error, setError] = useState(null);
  const sliderRef = useRef(null);
  const [centerIndex, setCenterIndex] = useState(0);

  useEffect(() => {
    axios.get('https://api.github.com/users/MahdiOsman/repos')
      .then(response => setRepos(response.data))
      .catch(error => {
        console.error('Error fetching the repos:', error);
        setError('Oops! Something went wrong while fetching the projects. Learn how to cats develop instead!');
      });
  }, []);

  const handleItemClick = (index) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };

  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    beforeChange: (oldIndex, newIndex) => setCenterIndex(newIndex),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '0px'
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '0px'
        }
      }
    ]
  };

  return (
    <div className="projects-content">
      <h2>Projects</h2>
      {error ? (
        <div className="error-container">
          <p className="error-message">{error}</p>
          <DevelopmentForCats />
        </div>
      ) : (
        <Slider ref={sliderRef} {...settings}>
          {repos.map((repo, index) => (
            <div 
              key={repo.id} 
              className={`project-item ${index === centerIndex ? 'slick-center' : ''}`} 
              onClick={() => handleItemClick(index)}
              style={{ cursor: 'pointer' }}
            >
              {index === centerIndex ? (
                <a 
                  href={repo.html_url} 
                  target="_blank" 
                  rel="noreferrer noopener" 
                  className="project-link"
                >
                  <h3>{repo.name}</h3>
                  <p>{repo.description}</p>
                </a>
              ) : (
                <>
                  <h3>{repo.name}</h3>
                  <p>{repo.description}</p>
                </>
              )}
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};

export default Projects;
